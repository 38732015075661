import React from 'react'
import {useState , forwardRef,useRef, useImperativeHandle, useEffect} from 'react'
import "../Styles/ReceiptTable.css"
const ReceiptTable = forwardRef( (props, ref) => {

    const [columns, ] = useState(props.columns);
    const [data, setData] = useState([]);
    const refTableBody = useRef(null)
    let refs = [];

    useEffect(() => {
        setData(props.data);
    }, [props.data])
    
    useImperativeHandle(ref,() => ({
        focusFirst: () =>{
            if(refs[0]){
                props.select(0, data[0]);  
                refs[0].focus();
            }
        }
    }))
    
    const select = (e) =>{ 
        let id = e.currentTarget.rowIndex-1;
        props.select(id, data[id]);  
        refs[id].focus();
    }

    const keyPress = (e) =>{
        let id = e.currentTarget.rowIndex - 1;
        switch (e.key) {
            case "Enter":
                props.select(id, data[id]);  
                props.focusNext();
                break;
            case "w":
            case "W":
                if(refs[id-1]){
                    refs[id-1].focus();
                    props.select(id-1, data[id-1]); 
                }
                break;
            case "S":
            case "s":
                if(refs[id+1]){
                    refs[id+1].focus();
                    props.select(id+1, data[id+1]);
                } 
                break;
            case "q":
            case "Q":
                props.select(null, null);
                props.focusStart();
                break;
            case "E":
            case "e":
                props.focusMotive();
                break;
            default:
                break;
        }
    }

    const saveRef = (e) => {
        refs.push(e);
    }

    return (
        <table className="table tablaRecibos" style={{position:"relative", margin:"auto", height:props.height , width:props.width}}>
            <thead onClick={(e) => console.log()} className='table-secondary'>
                <tr>
                    {columns.map((column, id) => (
                        <th key={column}>{column}</th>
                    ))}
                </tr>
            </thead>
            <tbody ref={refTableBody} style={{height:props.height}}>
                {data.map((fila, idx) =>(
                    <tr 
                        onKeyPressCapture={keyPress} 
                        tabIndex={0} 
                        ref={saveRef} 
                        key={idx} 
                        onClick={select}
                        style={(idx === props.selected)?{backgroundColor:"#003770", color:"#FFFFFF", transition:"0.75s"}:{transition:"0.1s"}}
                    >
                        <td >
                            <span>{fila.Fecha}</span>
                        </td>
                        <td>
                            <span>{fila.Nro}</span>
                        </td>
                        <td>
                            <span>{fila.Persona}</span>
                        </td>
                        <td>
                            <span>{new Intl.NumberFormat('en-US', { minimumFractionDigits: 2}).format(fila.Importe)}</span>
                        </td>
                        <td >
                            <span>{fila.Usuario}</span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
})

export default ReceiptTable
