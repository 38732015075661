import API from '../Conection/conection'

/** AUTHENTICATION SERVICE
 * Contiene las funciones basicas de login y logout
 * login: consulta a backend si el usuario con las credenciales proporcionadas puede ingresar al sistema
 * logout: elimina el token proporcionado del almacenamiento local
 */
class AuthService {
    //funcion de logueo
    async login(username, password) {
        //consulta con un usuario y contrasenia
        return await API.post('/user/login', {username, password})
                .then((response) => {
                    //verifica la existencia del token en la respuesta
                    if (response.data.response.accessToken) {
                        //gualda el token en localstorage
                        localStorage.setItem("user", JSON.stringify(response.data.response));
                    }
                    return response.data;
                })
    }
    //funcion de logout
    logout() {
        //elimina el token de localstorage
        localStorage.removeItem("user");
    }
}

export default AuthService;
