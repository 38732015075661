import { LOGIN_SUCCESS,
         LOGIN_FAIL,
         LOGOUT,
         SET_MESSAGE,
         SET_USER,
        } from "./types";
import AuthService from "../services/auth.service";

/**
 * Creador de acciones relacionadas con la autenticacion,
 * importa Authservice para las solicitudes HTTP asincronas para desencadenar dispatch
 */

const authService = new AuthService();
export const login = (username, password) => (dispatch) => {
    return authService.login(username, password).then(
      async (data) => {
        let response = null;
        if(data.errors.length > 0){
          response = {
            type: LOGIN_FAIL,
            payload: { user: data },
          };
        } else {
          response = {
            type: LOGIN_SUCCESS,
            payload: { user: data },
          };
        }
        await dispatch(response);
        return Promise.resolve(data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          //Se establece que el login ha fallado
        dispatch({
          type: LOGIN_FAIL,
        });
        //cambia el mensaje y el tipo
        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    );
};

export const logout = () => (dispatch) => {
    authService.logout();
    dispatch({
      type: LOGOUT,
    });
};

export const setUser = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER,
    payload,
  });
};
