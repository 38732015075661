import { Route, Routes } from 'react-router-dom'
import useRoutesByRole from 'hooks/useRoutesByRole'
import Reports from 'Pages/Reports'
import ReportsFacturas from 'Pages/ReportsFacturas'
import Sales from 'Pages/Sales'
import KardexValores from 'Pages/KardexValores'
import ReceiptSearch from 'Pages/ReceiptSearch'
import ChartAccount from 'Pages/ChartAccount'
import Locking from 'Pages/Locking'
import SuccessfulPayment from 'Pages/SuccessfulPayment'
import LastMovement from 'Pages/LastMovement'
import Facturas from 'Pages/Facturacion/Facturas'
import AnularFacturas from 'Pages/Facturacion/Anular'
import LoadingSection from 'Pages/LoadingSection'
import Home from 'Pages/Home'
import FacturaPDF from 'Pages/Facturacion/FacturaPDF'
import ComprobantePdf from 'Pages/ComprobantePdf'

// Datos de las rutas, par Ruta -> Datos de ruta
const pageRoutes = {
    sales: { path: '/sales', element: <Sales />, exact: true },
    reports: { path: '/reports', element: <Reports /> },
    receiptSearch: { path: '/receiptSearch', element: <ReceiptSearch /> },

    facturas: { path: '/facturas', element: <Facturas />, exact: true },
    anularFacturas: { path: '/anularFacturas', element: <AnularFacturas /> },
    reportsFacturas: { path: '/reportsFacturas', element: <ReportsFacturas /> },

    kardexValores: { path: '/kardexValores', element: <KardexValores /> },
    chartaccount: { path: '/chartaccount', element: <ChartAccount />, exact: true },
    locking: { path: '/locking', element: <Locking /> },
    'last-movement': { path: '/last-movement', element: <LastMovement /> }
}

export default function PrivateRoutes() {
    const routes = useRoutesByRole()

    return (
        <Routes>
            <Route index element={<LoadingSection />} />
            <Route path='/inicio' element={<Home />} />

            {routes.map((route, index) => (pageRoutes[route] ? <Route key={index} {...pageRoutes[route]} /> : null))}

            <Route path='/factura/:id' element={<FacturaPDF />} />
            <Route path='/comprobante/:id' element={<ComprobantePdf />} />
            <Route path='/pago_terminado' element={<SuccessfulPayment />} />
            <Route path='*' element={<LoadingSection />} />
        </Routes>
    )
}
