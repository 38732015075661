import { useEffect, useState } from 'react';
import logoUpsi from '../data/img/ContornoBlanco.png';


function Footer() {
    const [year, setYear] = useState('');

    useEffect(
        ()=>{setYear(new Date().getFullYear());}
    ,[]);

    return(
        <footer className='text-center text-white bg-blue py-1'>
            <span>
                © {year} UMSS. Desarrollado por <a href="https://software.umss.edu" target="_blank" style={{textDecoration:"none", color: "#5ED6DE" }}>Software San Simón - DTIC</a> 
            </span>
            <img style={{padding:"5px", marginLeft:20}} className="img-fluid" src={logoUpsi} alt="" width='60' />
        </footer>
    )
}
export default Footer;