import './Styles/all.css'
import './App.css'
import 'font-awesome/css/font-awesome.min.css'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Navbar from './Component/Navbar'
import { setUser } from './actions/auth'
import { history } from './helpers/history'
import Footer from './Component/Footer'
import LoadingSpinner from './Component/LoadingSpinner'
import PrivateRoutes from './routes/PrivateRoutes'
import PublicRoutes from './routes/PublicRoutes'
import { ApiServices } from './services/api.service'

function App() {
    const dispatch = useDispatch()
    const { isLoggedIn, user } = useSelector((state) => state.auth)

    useEffect(() => {
        if (isLoggedIn) {
            getDataUser()
        }
    }, [isLoggedIn])

    /** Obtiene la información y roles de usuario. */
    const getDataUser = async () => {
        let user = null
        let roles = []
        const resData = await ApiServices.getUserData()
        if (resData.status === 200) {
            user = resData.data
            const resRoles = await ApiServices.getRoles()
            if (resRoles.status === 200) {
                const { data } = resRoles
                roles = Object.keys(data).reduce((carry, rol) => {
                    if (data[rol] === true) carry.push(rol)
                    return carry
                }, [])
                dispatch(setUser({ user, roles }))
            }
        }
    }

    return (
        <BrowserRouter history={history}>
            <div className='main-container'>
                <Navbar />
                <div className='content-page'>
                    {isLoggedIn ? user ? <PrivateRoutes /> : <LoadingSpinner /> : <PublicRoutes />}
                </div>
                <Footer />
            </div>
        </BrowserRouter>
    )
}

export default App