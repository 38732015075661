export const titlePages = {
    receiptSearch: 'ANULAR/RE-IMPRIMIR RECIBOS',
    sales: 'VENTA DE VALORES',
    kardexValores: 'INGRESO DE VALORES',
    reports: 'REPORTE DE MOVIMIENTOS',
    chartaccount: 'TARIFARIO DE CUENTAS',
    searchAll: 'BUSCAR RECIBOS',
    locking: 'CIERRE DE MOVIMIENTOS',
    'last-movement': 'ELIMINAR MOVIMIENTO DE VALORADOS',
    facturas: 'FACTURAR',
    anularFacturas: 'ANULAR FACTURAS',
    reportsFacturas: 'REPORTE DE MOVIMIENTOS FACTURAS',
}

// Roles ordenados por prioridad, utilizado para obtener las rutas de un usuario
export const rolesByPriority = [
    'admin',
    ['adminReport', 'facturas'],
    'adminReport',
    ['report', 'facturas'],
    'report',
    'contabilidad',
    'dtic',

    // ['jefeCajero', 'facturas'],
    'facturas',
    ['jefeCajero', 'cajaCentral'],
    'jefeCajero',
    'cashier',
]

/**
 * Rutas por rol, par Rol -> Array de rutas, en orden de aparición en el navbar.
 * Para rutas que requieren mas de un rol se debe usar la sintaxis 'role-_-role'
 * y los roles deben estar en orden alfabético.
 */
export const routesByRole = {
    admin: ['reports', 'reportsFacturas', 'receiptSearch', 'anularFacturas', 'chartaccount'],

    contabilidad: ['reportsFacturas'],
    dtic: ['reports'],

    // Pregrado
    adminReport: ['reports', 'chartaccount'],
    report: ['reports', 'chartaccount'],
    'cajaCentral-_-jefeCajero': ['sales', 'receiptSearch', 'reports', 'chartaccount', 'locking', 'kardexValores', 'last-movement'],
    jefeCajero: ['sales', 'receiptSearch', 'reports', 'chartaccount', 'locking'],
    cashier: ['sales', 'receiptSearch', 'reports', 'chartaccount'],

    // Postgrado
    'adminReport-_-facturas': ['reportsFacturas', 'chartaccount'],
    'facturas-_-report': ['reportsFacturas', 'chartaccount'],
    'facturas-_-jefeCajero': ['facturas', 'anularFacturas', 'reportsFacturas', 'chartaccount'],
    facturas: ['facturas', 'anularFacturas', 'reportsFacturas', 'chartaccount'],
}

// Ruta de redireccion por rol, par Rol -> Ruta
export const redirectByRole = {
    admin: '/reports',

    dtic: '/reports',
    contabilidad: '/reportsFacturas',

    // Pregrado
    adminReport: '/reports',
    report: '/reports',
    'cajaCentral-_-jefeCajero': '/sales',
    jefeCajero: '/sales',
    cashier: '/sales',

    // Postgrado
    'adminReport-_-facturas': '/reportsFacturas',
    'facturas-_-report': '/reportsFacturas',
    'facturas-_-jefeCajero': '/facturas',
    facturas: '/facturas',
}
